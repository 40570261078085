const bo_routes = [
  {
    path: '',
    redirect: { name: 'BoLogin' },
  },
  {
    path: 'login',
    name: 'BoLogin',
    component: () => import('@/views/backend/BoLogin.vue'),
    meta: {
      redirectIfLoggedIn: true,
      layout: 'empty',
    }
  },
  {
    path: 'dashboard/:pageSlug?',
    name: 'BoDashboard',
    component: () => import('@/views/backend/BoDashboard.vue'),
    meta: {
      menu: 'BoDashboard',
      pageTitle: 'Dashboard',
      objName: 'Dashboard',
    },
  },
  {
    path: 'account-setting',
    name: 'BoAccountSetting',
    component: () => import('@/views/backend/BoAccountSetting.vue'),
    meta: {
      menu: 'BoAccount',
      pageTitle: 'Account Setting',
      objName: 'Account',      
    },
  },
  {
    path: 'user/:pageSlug?',
    name: 'Users',
    component: () => import('@/views/backend/BoUser/List.vue'),
    meta: {
      menu: "Users",
      pageTitle: 'Users',
      hasCta: 'true',
      objName: 'User'
    },
  },
  {
    path: 'user-level/:pageSlug?',
    name: 'BoUserLevel',
    component: () => import('@/views/backend/BoUserLevel/List.vue'),
    meta: {
      menu: 'BoUserLevel',
      pageTitle: 'User Level',
      hasCta: 'true',
      objName: 'User Level'
    },
  },
  {
    path: 'article-list/:pageSlug?',
    name: 'ArticleList',
    component: () => import('@/views/backend/ArticleList/List.vue'),
    meta: {
      menu:'ArticleList',
      pageTitle: 'Blog List',
      hasCta: true,
      objName: 'Blog'
    }
  },
  {
    path: 'article-category/:pageSlug?',
    name: 'ArticleCategory',
    component: () => import('@/views/backend/ArticleCategory/List.vue'),
    meta: {
      menu: 'ArticleCategory',
      pageTitle: 'Blog Category',
      hasCta: true,
      objName: 'Blog Category'
    }
  },
  {
    path: 'service/claim-information',
    name: 'ServiceClaimInfo',
    component: () => import('@/views/backend/ServiceClaimInfo/List.vue'),
    meta: {
      menu: 'ServiceClaimInfo',
      pageTitle: 'Claim Information',
      objName: 'Claim Information'
    }
  },
  {
    path: 'service/premi-payment',
    name: 'ServicePayPremi',
    component: () => import('@/views/backend/ServicePayPremi/List.vue'),
    meta: {
      menu:'ServicePayPremi',
      pageTitle: 'Premi Payment',
      objName: 'Premi Payment'
    }
  },
  {
    path: 'service/calculator',
    name: 'BoCalculator',
    component: () => import('@/views/backend/BoCalculator/BoCalculator.vue'),
    meta: {
      menu: 'BoCalculator',
      pageTitle: 'Calculator',
      objName: 'Calculator'
    }
  },
  {
    path: 'service/complain-handling',
    name: 'ServiceComplain',
    component: () => import('@/views/backend/ServiceComplain/List.vue'),
    meta: {
      menu:'ServiceComplain',
      pageTitle: 'Complain',
      objName: 'Complain'
    }
  },
  {
    path: 'investor-relation',
    name: 'BoInvRelate',
    component: () => import('@/views/backend/BoInvRelate/BoInvRelate.vue'),
    meta: {
      menu: 'BoInvRelate',
      pageTitle: 'Investor Relation',
      objName: 'Investor Relation',
    }
  },
  {
    path: 'investor-relation/company-information',
    name: 'InvRelateCompanyInfo',
    component: () => import('@/views/backend/InvRelateCompanyInfo/List.vue'),
    meta: {
      menu:'InvRelateCompanyInfo',
      pageTitle: 'Company Information',
      objName: 'Company Information',
    }
  },
  {
    path: 'investor-relation/more-information/:pageSlug?',
    name: 'BoInfo',
    component: () => import('@/views/backend/MoreInformation/MoreInformation.vue'),
    meta: {
      menu: 'BoInfo',
      pageTitle: 'RUPS Information',
      objName: 'RUPS Information',
      hasCta: true
    }
  },
  {
    path: 'contact/:pageSlug?',
    name: 'BoContact',
    component: () => import( /* webpackChunkName: "about" */ '@/views/backend/BoContact/BoContact.vue'),
    meta: {
      pageTitle: 'Contact',
      menu: 'BoContact',
      objName: 'Contact',
    }
  }, {
    path: 'office',
    name: 'BoOffice',
    component: () => import( /* webpackChunkName: "about" */ '@/views/backend/BoContact/BoOffice.vue'),
    meta: {
      pageTitle: 'Office',
      menu: 'BoOffice',
      objName: 'Office',
    }
  },
  {
    path: 'calculator-data/:pageSlug?',
    name: 'BoCalculatorData',
    component: () => import('@/views/backend/BoCalculatorData/BoCalculatorData.vue'),
    meta: {
      menu: 'BoCalculatorData',
      pageTitle: 'Premium Calculator Data',
      objName: 'Premium Calculator Data',
    }
  },
  {
    path: 'investor-relation/company-report',
    name: 'InvRelateReport',
    component: () => import('@/views/backend/InvRelateReport/List.vue'),
    meta: {
      menu:'InvRelateReport',
      pageTitle: 'Company Report',
      objName: 'Company Report',
    }
  },
  {
    path: 'investor-relation/company-governance',
    name: 'InvRelateGovernance',
    component: () => import('@/views/backend/InvRelateGovernance/List.vue'),
    meta: {
      menu:'InvRelateGovernance',
      pageTitle: 'Company Governance',
      objName: 'Company Governance',
    }
  },
  {
    path: 'investor-relation/informasi-saham',
    name: 'BoShareInformation',
    component: () => import('@/views/backend/BoShareInformation/BoShareInformation.vue'),
    meta: {
      menu: 'BoShareInformation',
      pageTitle: 'Stock Information',
      objName: 'Stock Information'
    }
  },
  {
    path: 'home',
    name: 'BoHome',
    component: () => import('@/views/backend/BoHome/BoHome.vue'),
    meta: {
      menu: 'BoHome',
      pageTitle: 'Home',
      objName: 'Home'
    }
  },
  {
    path: 'products/:pageSlug?',
    name: 'BoProducts',
    component: () => import('@/views/backend/BoProducts/BoProducts.vue'),
    meta: {
      pageTitle: 'Products',
      hasCta: true,
      menu: 'BoProducts',
      objName: 'Product',
    }
  },
  {
    path: 'career/:pageSlug?',
    name: 'BoCareer',
    component: () => import('@/views/backend/BoCareer/BoCareer.vue'),
    meta: {
      menu: 'BoCareer',
      pageTitle: 'Career',
      hasCta: true,
      hasBreadCrumb : false,
      objName: 'Vacancy',
    }
  },
  {
    path: 'job-applicants/:pageSlug?',
    name: 'BoApplicants',
    component: () => import('@/views/backend/BoCareer/BoApplicants.vue'),
    meta: {
      menu: 'BoApplicants',
      hasCta: false,      
      pageTitle: 'Job Applicants',
      objName: 'Job Applicants',
    }
  },
  {
    path: 'legal/:pageSlug?',
    name: 'BoTermCondition',
    component: () => import('@/views/backend/BoLegal/BoLegal.vue'),
    meta: {
      pageTitle: 'Legal',
      menu: 'BoTermCondition',
      hasBreadCrumb: false,
      objName: 'Term and Conditions',
    }
  },
  {
    path: 'legal/:pageSlug?',
    name: 'BoPrivacyPolice',
    component: () => import('@/views/backend/BoLegal/BoLegal.vue'),
    meta: {
      pageTitle: 'Legal',
      menu: 'BoPrivacyPolice',
      hasBreadCrumb: false,
      objName: 'Privacy Police',
    }
  },
  {
    path: 'csr/:pageSlug?',
    name: 'BoCsr',
    component: () => import('@/views/backend/BoCSR/BoCSR.vue'),
    meta: {
      menu: 'BoCsr',
      pageTitle: 'CSR',
      hasCta: true,
      objName: 'CSR Yearly Report'
    },
  },
  {
    path: 'web-settings',
    name: 'BoWebSettings',
    component: () => import('@/views/backend/BoWebSettings/BoWebSettings.vue'),
    meta: {
      pageTitle: 'Web Settings',
      menu: 'WebSettings',
      objName: 'Web Settings'
    }
  },
  {
    path: 'static-seo/:pageSlug?',
    name: 'StaticSEO',
    component: () => import('@/views/backend/StaticSEO/StaticSEO.vue'),
    meta: {
      pageTitle: 'Static SEO',
      menu: 'StaticSEO',
      objName: 'Static SEO',
      ctaList: true,
    }
  },
  {
    path: 'test-page',
    name: 'TestPage',
    component: () => import('@/views/backend/TestPage.vue'),
    meta: {
      pageTitle: 'Test Page',
      menu: 'TestPage',
      objName: 'Test Page',
      ctaList: false,
    }
  }
]
export default bo_routes